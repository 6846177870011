<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="mb-5 name">{{ wedding.man.surname }} & {{ wedding.woman.surname }} </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio ref="audio" :file="music.file" :loop="music.loop" :autoplay="music.autoplay"/>
    <AGWHero overlay :background="backgrounds" ref="hero" parallax target="#intro"/>

    <div class="bg-white agw-content" :style="{ 'margin-top' : `${$root.hero.height}px` }">
      <AGWIntro id="intro"/>

      <div class="pad-y-60">
        <AGWPawiwahan id="pawiwahan"/>
        <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes"/>
      </div>

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="pad-y-60"/>
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.800823469834!2d115.2217887147843!3d-8.710455593742195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd2416d510d706b%3A0x8991915853799c58!2sJl.%20Suwung%20Batan%20Kendal%20No.31%2C%20Sesetan%2C%20Denpasar%20Selatan%2C%20Kota%20Denpasar%2C%20Bali%2080223!5e0!3m2!1sen!2sid!4v1644811966268!5m2!1sen!2sid"
        directionUrl="https://www.google.com/maps/dir//Jl.+Suwung+Batan+Kendal+No.31,+Sesetan,+Denpasar+Selatan,+Kota+Denpasar,+Bali+80223/@-8.7104556,115.2217887,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2dd2416d510d706b:0x8991915853799c58!2m2!1d115.2239774!2d-8.7104556"
        :location="location"
        :time="time"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <Attendance id="attendance" class="pad-y-60"></Attendance>
      <!-- <WeddingGift class="mb-5" /> -->
    </div>

    <div :style="{ 'height' : `${$root.dividerHeight}px` }"></div>

    <section class="bg-white footer pad-y-20" ref="footer">
      <!-- <div class="">
        <img
          src="https://ik.imagekit.io/ceremonyku/partner/mank-dika-new_yUr0wpgCZKm?updatedAt=1634833463343&tr=w-300"
          class="mx-auto mb-2"
          style="max-width: 150px"
        />
      </div>
      <div class="flex mt-2 mb-2 w-full justify-center">
        <a
          href="https://www.instagram.com/mankdika_photography/"
          target="_blank"
          class="mx-2"
          ><svg
            id="instagram"
            xmlns="http://www.w3.org/2000/svg"
            width="26.694"
            height="26.694"
            viewBox="0 0 26.694 26.694"
          >
            <path
              id="Path_443"
              data-name="Path 443"
              d="M9.741,2H20.953a7.747,7.747,0,0,1,7.741,7.741V20.953a7.741,7.741,0,0,1-7.741,7.741H9.741A7.747,7.747,0,0,1,2,20.953V9.741A7.741,7.741,0,0,1,9.741,2M9.474,4.669a4.8,4.8,0,0,0-4.8,4.8V21.22a4.8,4.8,0,0,0,4.8,4.8H21.22a4.8,4.8,0,0,0,4.8-4.8V9.474a4.8,4.8,0,0,0-4.8-4.8H9.474m12.88,2A1.668,1.668,0,1,1,20.686,8.34a1.668,1.668,0,0,1,1.668-1.668m-7.007,2a6.673,6.673,0,1,1-6.673,6.673,6.673,6.673,0,0,1,6.673-6.673m0,2.669a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
              transform="translate(-2 -2)"
              fill="#283a60"
            ></path></svg
        ></a>
        <a href="https://wa.me/6289520498734" target="_blank" class="mx-2"
          ><svg
            id="whatsapp"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
          >
            <path
              id="Path_445"
              data-name="Path 445"
              d="M21.175,17.548c.325.169.533.26.6.39a2.559,2.559,0,0,1-.273,1.534,3.166,3.166,0,0,1-2.21,1.456c-.6.026-.611.468-3.848-.949s-5.187-4.875-5.343-5.1A6.3,6.3,0,0,1,8.9,11.49a3.543,3.543,0,0,1,1.235-2.652.969.969,0,0,1,.884-.338h.611c.2,0,.468-.078.715.585l.9,2.431a.593.593,0,0,1,.013.572l-.351.533-.507.546c-.156.156-.338.325-.156.65a9.648,9.648,0,0,0,1.716,2.314,8.59,8.59,0,0,0,2.535,1.69c.312.182.507.156.7-.052l1.053-1.222c.247-.325.455-.247.754-.143l2.171,1.144M15,2A13,13,0,1,1,8.045,25.985L2,28l2.015-6.045A13,13,0,0,1,15,2m0,2.6A10.4,10.4,0,0,0,6.5,20.993L5.25,24.75,9.007,23.5A10.4,10.4,0,1,0,15,4.6Z"
              transform="translate(-2 -2)"
              fill="#283a60"
            ></path></svg
        ></a>
      </div> -->
      <div class="font-weight-lighter">
        &copy; {{ $moment().format("YYYY") }}. Made & supported by
        <a href="https://ceremonyku.com">ceremonyku.com</a>.
      </div>
    </section>

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height"/>
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/ceremonyku/swandi-hellen/MDP_8430.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644812060051&tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/ceremonyku/swandi-hellen/MDP_8327.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644812058006&tr=w-800";
const ImageHero3 =
  "https://ik.imagekit.io/ceremonyku/swandi-hellen/MDP_8009.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644812054503&tr=w-800";

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Himbauan from '@/components/Himbauan.vue';
import Attendance from "@/components/Attendance";

import { setInterval } from "timers";
import WeddingGift from './components/WeddingGift.vue';

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    WeddingGift,
  },
  data() {
    return {
      title: 'Pawiwahan',
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      wedding: {
        man: {
          name: "Komang Swandiyasa, S.Si., M.Si",
          surname: "Swandi",
          parents: "I Made Sarda & Ni Ketut Suami",
          description: "Putra ketiga",
          address: "Suwung Batan Kendal, Kec. Denpasar Selatan",
          photo:
            "https://ik.imagekit.io/ceremonyku/swandi-hellen/MDP_8357.JPG?updatedAt=1644812225826&tr=w-400,h-501,fo-custom,cm-extract",
        },
        woman: {
          name: "Ni Ayu Hellen Yuniantari, S.Pd",
          surname: "Hellen",
          parents: "Purn. I Made Sudama & Siti Isdiastuti",
          address: "Br. Sengguan, Desa Tonja, Kec. Denpasar Utara",
          description: "Putri kedua",
          photo:
            "https://ik.imagekit.io/ceremonyku/swandi-hellen/MDP_8168.JPG?updatedAt=1644812178916&tr=w-732,h-915,fo-custom,cm-extract",
        },
      },
      location: "Jalan Suwung Batan Kendal No.31, Sesetan, Denpasar Selatan",
      time: {
        start: this.$moment("2022-03-14 11:00"),
        end: "Selesai"
      },
      mepandes: [
        'Ni Luh Sriani',
        'Komang Swandiyasa, S.Si., M.Si',
        'Ni Putu Nia Prasetya Dewi',
      ],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>
